import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "../school-information.module.scss"

function ArtsAndAthleticsSection({ index, header, image, imageAlt, text }) {
  if ((index + 1) % 2 === 0) {
    return (
      <div className={styles.even}>
        <div className={styles.arts_and_athletics_image}>
          <GatsbyImage alt={imageAlt} image={image} />
        </div>
        <div>
          <h2>{header}</h2>
          {text.map((obj, index) => {
            return <p key={index}>{obj.text}</p>
          })}
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.odd}>
        <div>
          <h2>{header}</h2>
          {text.map((obj, index) => {
            return <p key={index}>{obj.text}</p>
          })}
        </div>
        <div className={styles.arts_and_athletics_image}>
          <GatsbyImage alt={imageAlt} image={image} />
        </div>
      </div>
    )
  }
}

export default ArtsAndAthleticsSection
