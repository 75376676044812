import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { getFormattedDate, getFormattedDateTime } from "../../utils"
import { navItems } from "../school-information-nav-bar"
import * as styles from "../school-information.module.scss"

function FamilyTours() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsFamilyTours {
        edges {
          node {
            data {
              dates {
                date_time
              }
              image {
                alt
                gatsbyImageData
              }
              rsvp_info {
                text
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsFamilyTours.edges[0].node.data

  return (
    <section className={styles.section_half_circle}>
      <div
        id={navItems.familyTours.link.substring(1)}
        className={`${styles.generic_div} ${styles.family_tours}`}
      >
        <h1>Family Tours</h1>
        <p>
          We are excited to share our school with prospective Boggs School
          families!
        </p>
        <div className={styles.image}>
          <GatsbyImage
            image={data.image.gatsbyImageData}
            alt={data.image.alt}
          />
        </div>
        <div>
        <p>This school year's tour dates are as follows:</p>
          {data.dates.map((item, index) => {
            return <p key={index}>{getFormattedDateTime(item.date_time)}</p>
          })}
          <p>{data.rsvp_info.text}</p>
        </div>
      </div>
    </section>
  )
}

export default FamilyTours
