// extracted by mini-css-extract-plugin
export var anti_generic_div = "school-information-module--anti_generic_div--n9v5f";
export var application_image = "school-information-module--application_image--EKIzi";
export var application_image_container = "school-information-module--application_image_container--ioV5j";
export var applications = "school-information-module--applications--gzexy";
export var arts_and_athletics_all = "school-information-module--arts_and_athletics_all--j0CWv";
export var arts_and_athletics_divs = "school-information-module--arts_and_athletics_divs--L9pWi";
export var arts_and_athletics_image = "school-information-module--arts_and_athletics_image--ccgBw";
export var center = "school-information-module--center--LqXss";
export var centered = "school-information-module--centered--SeM+V";
export var chevron = "school-information-module--chevron--ufnB+";
export var container = "school-information-module--container--x8Zdw";
export var drills = "school-information-module--drills--rywd-";
export var drills_log = "school-information-module--drills_log--PV33K";
export var even = "school-information-module--even--w8LF1";
export var family_tours = "school-information-module--family_tours--Clw6X";
export var faq = "school-information-module--faq--nWGTd";
export var faqs = "school-information-module--faqs--IuMJM";
export var first_div = "school-information-module--first_div--ZI2U6";
export var generic_div = "school-information-module--generic_div--zLaFd";
export var handbook = "school-information-module--handbook--IqLiW";
export var image = "school-information-module--image--0hNhK";
export var odd = "school-information-module--odd--nNCda";
export var school_policies = "school-information-module--school_policies--JAer6";
export var section_bottom_triangle = "school-information-module--section_bottom_triangle--BNCGV";
export var section_boxes = "school-information-module--section_boxes--Dnuzm";
export var section_half_circle = "school-information-module--section_half_circle--JZJa+";
export var section_half_circle_h1_hack = "school-information-module--section_half_circle_h1_hack--HG-D+";
export var section_half_circle_hack = "school-information-module--section_half_circle_hack--sfhOl";
export var tinyier_email = "school-information-module--tinyier_email--f8WFo";