import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import * as styles from "../school-information.module.scss"
import ArtsAndAthleticsSection from "./arts-and-athletics-section"

function ArtsAndAthletics(props) {
  const containerAnimation = getContentSwapAnimation(props.visible)
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsArtsAndAthletics {
        edges {
          node {
            data {
              arts_and_athletics_group {
                section_header {
                  text
                }
                section_image {
                  alt
                  gatsbyImageData
                }
                section_text {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`${containerAnimation} ${styles.arts_and_athletics_all}`}>
      <section>
        <div className={`${styles.generic_div} ${styles.first_div}`}>
          <h1>Community Arts Programming &amp; Athletics</h1>
          <p className={styles.anti_generic_div}>
            At Boggs, we honor and highlight the existing community resources and
            human assets of the neighborhood so our young people experience a
            wide variety of learning opportunities.
          </p>
        </div>
        <hr />
        <div className={styles.arts_and_athletics_divs}>
          {query.allPrismicBoggsArtsAndAthletics.edges[0].node.data.arts_and_athletics_group.map(
            (obj, index) => {
              const { section_header, section_image, section_text } = obj

              return (
                <>
                  <ArtsAndAthleticsSection
                    key={index}
                    index={index}
                    header={section_header.text}
                    image={section_image.gatsbyImageData}
                    imageAlt={section_image.alt}
                    text={section_text.raw}
                  />
                  <hr />
                </>
              )
            }
          )}
        </div>
      </section>
    </div>
  )
}

export default ArtsAndAthletics
