import React from "react"
import { navItems } from "./school-information-nav-bar"
import FAQs from "./faqs"
import Applications from "./applications"
import FamilyAndStudentHandbook from "./family-and-student-handbook"
import Schedules from "./schedules"
import SchoolPolicies from "./school-policies"
import ArtsAndAthletics from "./arts-and-athletics" 

function SchoolInformationContentSwapper(props) {
  switch (props.current) {
    case navItems.faqs.name:
      return <FAQs visible={props.visible} />
    case navItems.applications.name:
      return <Applications visible={props.visible} />
    case navItems.artsAndAthletics.name:
      return <ArtsAndAthletics visible={props.visible} />
    case navItems.schedules.name:
    case navItems.schoolCalendars.name:
    case navItems.familyTours.name:
    case navItems.foodService.name:
      return <Schedules visible={props.visible} />
    case navItems.schoolPolicies.name:
      return <SchoolPolicies visible={props.visible} />
    case navItems.familyAndStudentHandbook.name:
      return <FamilyAndStudentHandbook visible={props.visible} />
    default:
      return <></>
  }
}

export default SchoolInformationContentSwapper
