import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { navItems } from "../school-information-nav-bar"
import * as styles from "../school-information.module.scss"


function SchoolCalendars() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsSchoolCalendars {
        edges {
          node {
            data {
              calendar_links {
                link {
                  url
                }
                text {
                  text
                }
              }
              years {
                text
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsSchoolCalendars.edges[0].node.data

  return (
    <section className={styles.section_half_circle_hack}>
      <div
        id={navItems.schoolCalendars.link.substring(1)}
        className={`${styles.first_div} ${styles.generic_div}`}
      >
        <h1>School Calendars</h1>
        <p className={styles.center}>{data.years.text}</p>
        {data.calendar_links.map((item, index) => {
          return (
            <a className={styles.center} target="_blank" rel="noreferrer" href={item.link.url}>
              <p tabIndex={0} key={index}>{item.text.text}</p>
            </a>
          )
        })}
      </div>
    </section>
  )
}

export default SchoolCalendars
