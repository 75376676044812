import React from "react"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import * as styles from "../school-information.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function Applications(props) {
  const containerAnimation = getContentSwapAnimation(props.visible)
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsApplicationInstructions {
        edges {
          node {
            data {
              app_instructions_link {
                text
              }
              application_policies_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              application_policies_link {
                text
              }
              application_text {
                raw
              }
            }
          }
        }
      }
    }
  `)

  const {
    app_instructions_link,
    application_policies_image,
    application_policies_link,
    application_text,
  } = query.allPrismicBoggsApplicationInstructions.edges[0].node.data

  return (
    <div className={containerAnimation}>
      <div
        className={`${styles.first_div} ${styles.generic_div} ${styles.applications}`}
      >
        <section>
          <h1>Application Instructions</h1>
          <p className={styles.centered}>
            Thank you for your interest in the Boggs School!
          </p>
          <a target="_blank" href={app_instructions_link.text}>
            <p tabIndex={0}>Application</p>
          </a>
          <p>
            {application_text.raw.map((obj, index) => {
              return <p key={index}>{obj.text}</p>
            })}
          </p>
        </section>
        <section>
          <h2>Applications can be:</h2>
          <ul>
            <li>Downloaded and printed from this website (above).</li>
            <li>
              Mailed to you by request. Please send an email to
              boggsschool@boggsschool.org and indicate your address and how many
              applications you would like.
            </li>
            <li>
              Picked up at the Boggs School Monday through Friday 9am to 4pm,
              Monday through Friday.
            </li>
          </ul>
          <h2>Signed applications may be returned:</h2>
          <ul>
            <li>
              By mail: The Boggs School, 7600 Goethe Street, Detroit, MI 48214,
              Attn: Office Administrator
            </li>
            <li>
              By email:{" "}
              <a className="inline" href="mailto:boggsschool@boggsschool.org">
                <p className={`inline ${styles.tinyier_email}`} tabIndex={0}>
                  boggsschool@boggsschool.org
                </p>
              </a>
              <br />
              <br />
              Note: Emailed applications must be signed.
            </li>
          </ul>
          <p>
            Please don't hesitate to contact us at{" "}
            <a className="inline" mailto="boggsschool@boggsschool.org">
              <p className={`inline ${styles.tinyier_email}`} tabIndex={0}>
                boggsschool@boggsschool.org
              </p>
            </a>{" "}
            if you have any questions.
          </p>
        </section>
      </div>
      <div className={`${styles.generic_div} ${styles.applications}`}>
        <a href={application_policies_link.text}>
          <div tabIndex={0} className={styles.application_image_container}>
            <GatsbyImage
              className={styles.application_image}
              alt={application_policies_image.alt}
              image={application_policies_image.gatsbyImageData}
            />
          </div>
          <p tabIndex={0}>Applications Policies PDF</p>
        </a>
      </div>
    </div>
  )
}

export default Applications
