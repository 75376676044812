import React from "react"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "../school-information.module.scss"

function SchoolPolicies(props) {
  const containerAnimation = getContentSwapAnimation(props.visible)
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsSchoolPolicies {
        edges {
          node {
            data {
              intro {
                text
              }
              policies {
                link {
                  url
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsSchoolPolicies.edges[0].node.data
  data.policies.sort((a, b) => (a.text.text > b.text.text ? 1 : -1))
  return (
    <div className={containerAnimation}>
      <section>
        <div
          className={`${styles.first_div} ${styles.generic_div} ${styles.school_policies}`}
        >
          <h1>School Policies</h1>
          <p>{data.intro.text}</p>
          <ul>
            {data.policies.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.link.url} rel="noreferrer" target="_blank">
                    <p tabIndex={0}>{item.text.text}</p>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </div>
  )
}

export default SchoolPolicies
