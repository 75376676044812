import React from "react"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../school-information.module.scss"

function FamilyAndStudentHandbook(props) {
  const containerAnimation = getContentSwapAnimation(props.visible)
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsFamilyAndStudent {
        edges {
          node {
            data {
              image {
                alt
                gatsbyImageData
              }
              link {
                url
              }
              text {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsFamilyAndStudent.edges[0].node.data

  return (
    <div className={containerAnimation}>
      <section>
        <div
          className={`${styles.first_div} ${styles.generic_div} ${styles.handbook}`}
        >
          <h1>Family &amp; Student Handbook</h1>
          <p>{data.text.text}</p>
          <br />
          <a target="_blank" rel="noreferrer" href={data.link.url}>
            <p tabIndex={0}>Link</p>
          </a>
          <div className={styles.image}>
            <GatsbyImage
              alt={data.image.alt}
              image={data.image.gatsbyImageData}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default FamilyAndStudentHandbook
