import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { headerNames } from "../components/navigation/header"
import SchoolInformation from "../components/school-information"
import { navItems } from "../components/school-information/school-information-nav-bar"

function SchoolInformationPage() {
  const [currentSwapperItem, setCurrentSwapperItem] = useState(
    navItems.faqs.name
  )
  const [swapperVisible, setSwapperVisible] = useState(true)

  return (
    <Layout
      activeHeader={headerNames.schoolInformation}
      setCurrentSwapperItem={setCurrentSwapperItem}
      setSwapperVisible={setSwapperVisible}
    >
      <SEO title="School Information" />
      <SchoolInformation
        currentSwapperItem={currentSwapperItem}
        swapperVisible={swapperVisible}
      />
    </Layout>
  )
}

export default SchoolInformationPage
