import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { navItems } from "../school-information-nav-bar"
import * as styles from "../school-information.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

function FoodService() {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsFoodService {
        edges {
          node {
            data {
              blurb {
                text
              }
              image {
                gatsbyImageData
                alt
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsFoodService.edges[0].node.data

  return (
    <section
      className={`${styles.section_half_circle} ${styles.section_half_circle_h1_hack}`}
    >
      <div
        id={navItems.foodService.link.substring(1)}
        className={styles.generic_div}
      >
        <h1>Food Service</h1>
        <p>{data.blurb.text}</p>
        <div className={styles.center}>
          <GatsbyImage
            image={data.image.gatsbyImageData}
            alt={data.image.alt}
          />
        </div>
        <p>
          Please find the link to the most current menu{" "}
          <a href={data.link.url} target="_blank">
            here
          </a>
          {"."}
        </p>
      </div>
    </section>
  )
}

export default FoodService
