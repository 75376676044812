import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { splitOnNewline } from "../../utils"
import * as styles from "../school-information.module.scss"
import FAQ from "./faq"

function FAQs(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsFaqs {
        edges {
          node {
            data {
              question_and_answer {
                answer {
                  text
                }
                question {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={containerAnimation}>
      <section>
        <div className={`${styles.first_div} ${styles.generic_div}`}>
          <h1>Frequently Asked Questions</h1>
          <div className={styles.faqs}>
            {query.allPrismicBoggsFaqs.edges[0].node.data.question_and_answer.map(
              (faq, index) => {
                let answer = splitOnNewline(faq.answer.text)

                return (
                  <FAQ
                    key={index}
                    question={faq.question.text}
                    answer={answer}
                  />
                )
              }
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default FAQs
