import React from "react"
import SchoolInformationContentSwapper from "./school-information-content-swapper"
import * as styles from "./school-information.module.scss"

function SchoolInformation({currentSwapperItem, swapperVisible}) {
  return (
    <div className={styles.container}>
      <span />
      <SchoolInformationContentSwapper
        current={currentSwapperItem}
        visible={swapperVisible}
      />
    </div>
  )
}

export default SchoolInformation
