import React from "react"
import SchoolCalendars from "./school-calendars"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import FamilyTours from "./family-tours"
import FoodService from "./food-service"

function Schedules(props) {
  const containerAnimation = getContentSwapAnimation(props.visible)

  return (
    <div className={containerAnimation}>
      <SchoolCalendars />
      <FamilyTours />
      <FoodService />
    </div>
  )
}

export default Schedules
