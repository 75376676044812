import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import * as styles from "../school-information.module.scss"

function FAQ(props) {
  const [open, setOpen] = useState(false)

  if (open) {
    return (
      <div>
        <button className={styles.faq} onClick={() => setOpen(!open)}>
          <strong>
            <p>{props.question}</p>
          </strong>
          <div className={styles.chevron}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>
        {props.answer.map((text, index2) => {
          return <ReactMarkdown key={index2} source={text} />
        })}
      </div>
    )
  } else {
    return (
      <div className={styles.faq}>
        <button onClick={() => setOpen(!open)}>
          <strong>
            <p>{props.question}</p>
          </strong>
        </button>
        <div className={styles.chevron}>
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
    )
  }
}

export default FAQ
